export const environment = {
  baseClientUrl: 'https://matchamigo.invenit.dev/',
  apiUrl: 'https://matchamigos.invenit.dev/graphql/',
  fileUpload: 'https://matchamigos.invenit.dev/file/upload',
  fileDownload: 'https://matchamigos.invenit.dev/file/download?FileId=',
  wssUrl: '',

  production: false,

  // TODO add rest endpoint to upload file
  fileUploadUrl: '',

  // TODO add rest endpoint to download file
  fileDownloadUrl: '',
};
